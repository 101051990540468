<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="1200px"
    top="5vh"
    @close="dialogReset">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        :label-width="$t('user.labelWidth')"
        :model="user"
        :rules="ruleValidate">
        <el-form-item :label="$t('user.username')" prop="username">
          <el-input
            v-model="user.username"
            :placeholder="$t('common.pleaseEnter')"
            auto-complete="off"></el-input>
        </el-form-item>
        <el-form-item v-if="user.id === 0" :label="$t('user.password')" prop="password">
          <el-input
            v-model="user.password"
            type="text"
            :class="isShowPassword ? '' : 'displayPass'"
            :placeholder="$t('common.pleaseEnter')"
            autocomplete="new-password"></el-input>
          <i
            class="el-icon-view"
            :class="{ icon: isShowPassword }"
            style="position: absolute;right: 8px;top: 8px;color: #e6e6e6"
            @click="show"></i>
        </el-form-item>
        <el-form-item :label="$t('user.name')" prop="name">
          <el-input v-model="user.name" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item v-if="currentUserType===0" :label="$t('user.type')" prop="type">
          <el-select v-model="user.type" style="width: 100%" @change="onUserTypeChange">
            <el-option v-for="item in userTypes" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item
          v-if="currentUserType!==1 && currentUserType!==2 && (user.type===1||user.type===2)"
          :label="$t('user.company')" prop="orgId">
          <vm-select-input v-model="user.orgName" @select="$refs.selectCompany.open()"></vm-select-input>
          <company-select ref="selectCompany" @select="onOrgSelect"></company-select>
        </el-form-item>

        <el-form-item v-else-if="currentUserType!==1 && currentUserType!==2 && (user.type===3||user.type===4)"
                      :label="$t('user.quality')" prop="orgId">
          <vm-select-input v-model="user.orgName" @select="$refs.selectQuantity.open()"></vm-select-input>
          <quantity-select ref="selectQuantity" @select="onOrgSelect"></quantity-select>
        </el-form-item>

        <el-form-item :label="$t('user.messageSendType')">
          <el-radio-group v-model="user.messageSendType">
            <el-radio-button label="phone">{{$t("user.phone")}}</el-radio-button>
            <el-radio-button label="email">{{$t("user.mail")}}</el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('user.mail')" prop="email">
          <el-input v-model="user.email" :placeholder="$t('common.pleaseEnter')"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.phone')" prop="phone">
          <el-input v-model="user.phone" :placeholder="$t('common.pleaseEnter')" :maxlength="20"></el-input>
        </el-form-item>
        <el-form-item :label="$t('user.maintainer')" prop="isMaintWorker">
          <el-select v-model="user.isMaintWorker" style="width: 100%">
            <el-option :label="$t('common.yes')" :value="1"></el-option>
            <el-option :label="$t('common.no')" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('user.afterSale')" prop="isAfterSale">
          <el-select v-model="user.isAfterSale" style="width: 100%">
            <el-option :label="$t('common.yes')" :value="true"></el-option>
            <el-option :label="$t('common.no')" :value="false"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('user.clientUser')" prop="isClientUser">
          <el-select v-model="user.isClientUser" style="width: 100%">
            <el-option :label="$t('common.yes')" :value="true"></el-option>
            <el-option :label="$t('common.no')" :value="false"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('elevator.salesman')" prop="isSalesman">
          <el-select v-model="user.isSalesman" style="width: 100%">
            <el-option :label="$t('common.yes')" :value="true"></el-option>
            <el-option :label="$t('common.no')" :value="false"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="$i18n.isCn" :label="$t('user.cegion')" prop="area">
          <el-input v-model="user.area" :placeholder="$t('user.area')"></el-input>
        </el-form-item>

        <el-form-item v-if="$i18n.isCn" :label="$l('user.branch')" prop="branchCompany">
          <el-input v-model="user.branchCompany" :placeholder="$t('user.childCom')"></el-input>
        </el-form-item>

        <el-form-item v-if="$i18n.isCn" :label="$t('user.serviceStation')" prop="serviceStation">
          <el-input v-model="user.serviceStation" :placeholder="$t('user.station')"></el-input>
        </el-form-item>

        <el-form-item :label="$t('user.messageControl')">
          <el-checkbox-group v-model="messageControlList">
            <el-checkbox label="Repair">{{$t("user.repair")}}</el-checkbox>
            <el-checkbox label="Maintenance">{{$t("user.maintenance")}}</el-checkbox>
            <el-checkbox label="Alarm">{{$t("user.alarm")}}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item :label="$t('user.changePwdState')">
          <el-switch
            v-model="user.changePwdState"
            :active-text="$t('elevator.yes')"
            :inactive-text="$t('elevator.not')">
          </el-switch>
        </el-form-item>
      </el-form>
      <el-transfer
        v-model="user.roleIds"
        class="transfer"
        filterable
        :data="roleList"
        :props="{ key: 'id', label: $i18n.isCn ? 'name': 'name'}"
        :titles="[$t('user.unselectedRole'), $t('user.selectedRole')]"></el-transfer>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import CompanySelect from "../company/CompanySelect.vue";
  import QuantitySelect from "../quantity/QuantitySelect.vue";
  import VmSelectInput from "../../components/VmSelectInput";
  import auth from "../../util/auth";
  import validateUtil from "../../util/validateUtil";

  const moduleName = "users";
  const moduleName_role = "roles/new";

  export default {
    components: {CompanySelect, QuantitySelect, VmSelectInput},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        currentUserType: auth.getUserType(),
        roleList: [],
        userTypes: [],
        messageControlList: [],
        isShowPassword: false,
        user: {
          id: 0,
          type: 0,
          username: "",
          password: "",
          serviceStation: "",
          branchCompany: "",
          area: "",
          name: "",
          email: "",
          orgId: 0,
          orgName: "",
          messageSendType: this.$i18n.isCn ? "phone" : "email",
          phone: "",
          messageControl: "",
          changePwdState: true,
          roleIds: [],
          isMaintWorker: 0,
          isSalesman: false,
          isAfterSale: false,
          isClientUser: false,
        },
      };
    },
    computed: {
      title() {
        return (this.user.id === 0 ? this.$t("common.add") : this.$t("common.edit")) + " " + this.$t("user.user");
      },
      ruleValidate() {
        return {
          username: {
            required: true,
            pattern: /^[a-zA-Z0-9_@.]{4,25}$/,
            message: this.$t("user.userNameRule"),
            trigger: "blur",
          },
          name: {required: true, message: this.$t("common.tip.notNull"), trigger: "blur"},
          orgId: {validator: this.checkOrg, required: true, trigger: "change"},
          password: {
            required: true,
            pattern: /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,20}$/,
            message: this.$t("user.passwordRule"),
            trigger: "blur",
          },
          email: {
            required: this.user.messageSendType === "email",
            type: "email",
            message: this.$t("user.tip.mail"),
            trigger: "blur",
          },
          phone: {
            required: this.user.messageSendType === "phone",
            pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|19[0-9]|14[57])[0-9]{8}$/,
            message: this.$t("user.tip.phone"),
            trigger: "blur",
          },
        };
      },
    },
    methods: {
      open(id) {
        this.user.id = id;
        this.initUserTypes();
        this.getRoles();
        this.dialogVisible = true;
        this.messageControlList = ["Repair", "Maintenance", "Alarm"]; // 默认选中
      },
      show() {
        this.isShowPassword = !this.isShowPassword;
      },
      dialogReset() {
        this.user.roleIds = [];
        this.messageControlList = []; // 清空
        this.user.password = "";
        this.$refs.formValidate.resetFields();
      },
      getRoles() {
        if (this.roleList.length === 0) { // 此时需要获取角色列表
          let params = {
            pageSize: 1000,
          };
          this.setLoading(true);
          this.$api.getList(moduleName_role, params).then(res => {
            this.setLoading(false);
            this.roleList = res.data.records;
            for (let i = 0; i < this.roleList.length; i++) {
              if (!this.$i18n.isCn) {
                this.roleList[i].name = this.roleList[i].nameEn || this.roleList[i].name;
              }
            }
            if (this.user.id !== 0) { // 编辑页面需要获取role
              this.getUser();
            }
          }).catch(() => {
            this.$message.error(this.$t("common.tip.getDataError"));
            this.contentLoading = false;
          });
        } else if (this.user.id > 0) {
          this.getUser();
        }
      },
      getUser() {
        this.setLoading(true);
        this.$api.getById(moduleName, this.user.id).then(res => {
          this.setLoading(false);
          this.user.roleIds = res.data.roleIds;
          this.user = res.data;
          this.user.messageSendType = res.data.messageSendType || "phone";
          if (this.user.messageControl === null || this.user.messageControl === "") {
            this.messageControlList = [];
          } else {
            this.messageControlList = this.user.messageControl.split(",");
          }
        }).catch(() => {
          this.$message.error(this.$t("common.tip.getDataError"));
          this.contentLoading = false;
        });
      },
      initUserTypes() {
        let types = [];
        let items = [0, 1, 3, 9];
        for (let type of items) {
          let item = {
            value: type,
            label: this.$t("user.types." + type),
          };
          types.push(item);
        }
        this.userTypes = types;
      },
      onOrgSelect(row) {
        this.user.orgId = row.id;
        this.user.orgName = row.name;
      },
      onUserTypeChange() {
        this.user.orgId = 0;
        this.user.orgName = null;
      },
      setLoading(flag) {
        this.contentLoading = flag;
        this.saveDisabled = flag;
      },
      checkOrg(rule, value, callback) {
        if (this.user.type !== 0) {
          if (!value || value === 0) {
            callback(new Error(this.$t("common.tip.notNull")));
          } else {
            callback();
          }
        } else {
          callback();
        }
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.user.messageControl = "";
            for (let messageControl of this.messageControlList) {
              this.user.messageControl = this.user.messageControl + messageControl + ",";
            }
            this.$api.save(moduleName, this.user).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.user.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.displayPass {
  //将文本替换为指定的字符
  -webkit-text-security: disc;
}
.body {
  display: flex;

  .form {
    flex: 1;
    margin-right: 30px;
  }
}
</style>
