<template>
  <el-dialog
    v-el-drag-dialog
    :title="$t('user.lockedAccount')"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    width="500px"
    top="3vh">
    <el-table v-loading="contentLoading" border stripe :data="tableData">
      <el-table-column
        prop="username"
        align="center"
        :label="$t('user.username')"></el-table-column>
      <el-table-column
        align="center"
        :label="$t('common.operation')">
        <template v-slot="scope">
          <el-button type="danger" @click="unlock(scope.row.username)">{{$t("user.unlock")}}</el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        tableData: [],
      };
    },
    methods: {
      open() {
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        this.contentLoading = true;
        this.$http
          .get("captcha/lockedUsers")
          .then(res => {
            this.tableData = res.data;
          })
          .finally(() => {
            this.contentLoading = false;
          });
      },
      unlock(username) {
        this.$http
          .get("captcha/unlock?username=" + username)
          .then(() => {
            this.$message.success(this.$t("user.unlockSuccess"));
            this.getData();
          });
      },
    },
  };

</script>
<style lang="scss" scoped>
</style>
